import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取故障类型
 * @param {Object} data
 */
export function getDictList (data) {
  return request(
    {
      url: '/ooh-msp/v2/dictionary/getdictlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取拍照要求字典
 * @param {Object} data
 */
export function getPhotoDemandDictList (data) {
  return request(
    {
      url: '/ooh-msp/v2/dictionary/getphotodemanddictlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取维修项数据
 * @param {Object} data
 */
export function getRepairDictList (data) {
  return request(
    {
      url: '/ooh-msp/v2/dictionary/getrepairdictlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
